/** @jsxImportSource @emotion/react */
import { 
  ComponentProps, useState, 
} from 'react';
import * as d3 from "d3";

import theme, { refVh } from "../../../../theme";
import BasicNarration from '../../components/BasicNarration/BasicNarration';
import BasicChartCont from '../../components/BasicChartCont/BasicChartCont';
import Chart from "./Chart";
import fingerClickIconUrl from "./assets/finger-click-icon.svg";

export type Props = ComponentProps<"div"> & {
};

const chartProgTriggers = Array.from({length:7}, (_, idx) => {
  return (50+50*idx) / (50+50*7);
});

const AssetsOverview = (po: Props) => {
  const [chartState, setChartState] = useState(0);

  const onTagLnNarrProgChg = (prog: number) => setChartState(d3.bisect(chartProgTriggers, prog));

  return  (
    <div
      className={["assets-overview", "section", po.className].join(" ")}
      css={[
        theme.mixin.sectionBaseStyles,
        {
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }
      ]}
    >
      <div className={["narration-container"].join(" ")}>
        <BasicNarration
          onEnter={() => setChartState(8)}
          onExit={() => setChartState(0)}
        >
          Whether cryptocurrencies are judged eventually as a financial innovation or a speculative bubble, Bitcoin has already achieved the fastest-ever price appreciation of any must-have asset.
        </BasicNarration>

        <BasicNarration
          onEnter={() => setChartState(0)}
          onExit={() => setChartState(7)}
          onProgChg={onTagLnNarrProgChg}
          css={{
            height: 50+50*7 * refVh,
            justifyContent: "start",
            paddingTop: 50 * refVh,
          }}
        >
          <div css={{
            position: "sticky",
            top: "50%",
            transform: "translate(0, -50%)",
          }}>
            Each of these predecessors began with a compelling narrative and a tagline.
          </div>
        </BasicNarration>

        <BasicNarration
          onEnter={() => setChartState(8)}
          onExit={() => setChartState(8)}
        >
          And each delivered extraordinary price momentum that challenged standard valuation models at that time.
        </BasicNarration>
      </div>

      <BasicChartCont
        css={{flexGrow: 1}}
        chartTitle="Figure 1: The hype cycle - Bitcoin ascent has been steeper than any other financial innovation or assset bubbles of the past 50 years"
        chartFooter={(
          <>
            <div css={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              fontStyle: "italic",
              fontSize: "1.5rem",
            }}>
              <img src={fingerClickIconUrl} alt=""
                css={{
                  width: "1.8em",
                  height: "1.8em",
                  position: "relative",
                  bottom: "0.3em",
                }}
              />
              Click to select or de-select data in the chart
            </div>

            <p>Source: J.P. Morgan</p>
          </>
        )}
      >
        <Chart chartState={chartState} 
          css={{height: "70%"}}
        />
      </BasicChartCont>

    </div>
  );
}

export default AssetsOverview;
