/** @jsxImportSource @emotion/react */
import { 
  ComponentProps, useState, useRef 
} from 'react';

import theme, { refVh } from "../../../../../../theme";
import BasicNarration from '../../../../components/BasicNarration/BasicNarration';
import BasicChartCont from '../../../../components/BasicChartCont/BasicChartCont';
import Table from "./Table";

export type Props = ComponentProps<"div"> & {
};

const NarratedTable = (po: Props) => {
  const [prog, setProg] = useState(0);

  return  (
    <div
      css={[
        theme.mixin.sectionBaseStyles,
        {
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }
      ]}
    >
      <div className={["narration-container"].join(" ")}>
        <BasicNarration 
          css={{height: 450 * refVh, justifyContent: "space-evenly",}}
          onProgChg={(prog) => setProg(prog)}
        >
          <p>For tactical investors focused on risks that could crystallize over the next year, the better test of hedge effectiveness is whether a defensive or quasi-defensive asset rallies when Equities experience a material drawdown of perhaps 10% on Global Equities.</p>
          <p>During the 20 largest Global Equity corrections of the past decade, Bitcoin ranks as the worst in terms of median returns (-5%) and the third worst in terms of success rate (42%).</p>
          <p>Gold is slightly better on both metrics (52% success rate, 2.5% returns), but inferior to fiat currencies like USD vs EM FX (100% success rate, 3% returns) and JPY vs USD (86% success, 2% returns).</p>
          <p>Drawdown calculated as maximum peak-to-trough move during episode. Returns on other asset classes calculated as total return over same window. Green indicates positive return on hedge during equity decline.</p>
        </BasicNarration>
      </div>

      <BasicChartCont
        css={{flexGrow: 1}}
        chartTitle="Table 2: Returns on traditional and non-traditional defensive assets during largest global equity drawdown since 2009"
        chartFooter={(
          <p>Source: J.P. Morgan, Bloomberg Finance L.P.</p>
        )}
      >
        <Table prog={prog} />
      </BasicChartCont>

    </div>
  );
}

export default NarratedTable;
