/** @jsxImportSource @emotion/react */
import { PropsWithChildren, useEffect, useState, useRef } from 'react';
import * as d3 from "d3";

import theme from "../../theme";
import { ScrollyBody } from "../../features/scrolly";
import { NavIndicator } from "../../features/navIndicator";
import { 
  VideoBkg,
  Intro, AssetsOverview, WhyCrypto, TwoCriteria, Conclusion,
} from "../../features/contents";

type Props = PropsWithChildren<{
}>;

const scrollyBodyMaxWidth = theme.media.screen.xlMinWidth;

const Home = (po: Props) => {
  const [isIntroActive, setIsIntroActive] = useState(true);
  const [secElList, setSecElList] = useState<HTMLElement[]>([]);
  
  // used ref for progList because we want to avoid refreshing the Home component
  // on every scroll / prog change, which will makes the site very laggy
  const secProgListRef = useRef<number[]>([]);
  const setSecProgListRef = useRef<(progList: number[]) => void>( ()=>{} );

  useEffect(() => {
    setSecElList(d3.selectAll<HTMLElement, any>(".page.home .section").nodes());
  }, [])

  return (
    <div className={["page", "home"].join(" ")}
      css={{
        color: theme.color.white,
        fontFamily: "amplitude",
      }}
    >
      <VideoBkg isShown={isIntroActive}
        css={{
          position: "fixed",
          top: 0,
          left:0,
        }} 
      />
    
      <ScrollyBody
        onSecProgChg={ls => {
          secProgListRef.current = ls;
          setSecProgListRef.current(secProgListRef.current);
        }}
        isDebug={process.env.NODE_ENV === "development" ? true : false}
        css={{
          position: "relative",
          maxWidth: scrollyBodyMaxWidth,
          margin: "auto",
        }} 
      > 
        <NavIndicator 
          progList={secProgListRef.current} 
          elList={secElList}
          updateProgListRefCb={fn => setSecProgListRef.current = fn}
          css={{
            position: "fixed",
            top: window.innerHeight/2,
            // TODO: this does not work well screen size 
            //   smaller than 'scrollyBodyMaxWidth'
            //   (most likely tablet or old laptops)
            left: window.innerWidth/2 + scrollyBodyMaxWidth/2,
            width: "3rem",
            padding: "0 0 0 1rem",
            transform: "translate(0, -50%)",
            zIndex: 10,
          }}
        />

        <Intro 
          onEnter={() => setIsIntroActive(true)}
          onExit={() => setIsIntroActive(false)}
          onProgChg={prog => prog >= 0.55 ? setIsIntroActive(false) : setIsIntroActive(true)}
        />
        <AssetsOverview />
        <WhyCrypto />
        <TwoCriteria />
        <Conclusion />
      </ScrollyBody>

    </div>
  );
}

export default Home;
