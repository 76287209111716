/** @jsxImportSource @emotion/react */
import { ComponentProps, useEffect, useState, useRef } from 'react';
import lottie, { AnimationItem } from "lottie-web";

import theme, { refVh } from "../../../../../../theme";
import { ScrollyStep } from "../../../../../scrolly";
import diskAniJson from "../../assets/disk-ani.json";

type LottieAni = {
  instance: AnimationItem,
  duration: number,  // ms
  frames: number,
}

export type Props = ComponentProps<"div"> & {
  onEnter?: (dir: "up"|"down") => void,
  onExit?: (dir: "up"|"down") => void,
};

const Step01 = (po: Props) => {
  const {onEnter, onExit} = po;
  const [diskAni, setDiskAni] = useState<LottieAni|null>(null);
  const diskAniElRef = useRef<HTMLDivElement>(null);

  useEffect(()=> {
    const umbrellaAniEl = diskAniElRef.current!;
    
    const aniItem = lottie.loadAnimation({
      container: umbrellaAniEl,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: diskAniJson,
    });

    setDiskAni({
      instance: aniItem,
      // x1000 because it return sec but goToAndStop seems to except ms
      duration: aniItem.getDuration(false) * 1000,
      frames: aniItem.getDuration(true),
    })
  }, []);

  return (
    <ScrollyStep
      onEnter={dir => {
        diskAni && diskAni.instance.goToAndPlay(0);
        onEnter && onEnter(dir);
      }}
      onExit={onExit}
    >
      <div css={{
        height: 180 * refVh,
        padding: `${50*refVh}px 8rem 0 8rem`,
      }}>
        <div
          className={["splash"].join(" ")}
          css={{
            position: "sticky",
            top: "50%",
            transform: "translate(0, -50%)",

            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <div ref={diskAniElRef}
            className="disk-roll-in-animation"
            css={{
              height: "20rem",
              transform: "translate(30%, 0%)",
            }}
          />

          <h2 css={{
            fontWeight: theme.font.weight.light,
            textAlign: "center",
            paddingBottom: "6rem",  // to shift up closer to hori center

            "span": {
              display: "block",
            }
          }}>
            <span css={{
              fontSize: "3.5rem",
            }}>Why consider</span>
            <span css={{
              fontSize: "8.5rem",
              marginTop: "-2rem",
              fontWeight: theme.font.weight.normal,
            }}>Cryptocurrencies</span>
            <span css={{
              fontSize: "2.2rem",
            }}>an unconventional and high-volatility hedge, in a portfolio?</span>
          </h2>
        </div>
      </div>
    </ScrollyStep>
  );
}

export default Step01;
