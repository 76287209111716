/** @jsxImportSource @emotion/react */
import React, { ComponentProps } from 'react';

import theme, { refVh } from "../../../../theme";

export type Props = ComponentProps<"div"> & {
  chartTitle?: React.ReactNode,
  chartFooter?: React.ReactNode,
};

const BasicChartCont = React.forwardRef<HTMLDivElement, Props>((po, ref) => {
  const {
    className, children,
    chartTitle, chartFooter,
    ...poRest
  } = po;
  
  return (
    <div ref={ref}
      className={["chart-container", className].join(" ")}
      css={[
        theme.mixin.sectionBaseStyles,
        {
          position: "sticky",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          top: 0,

          height: 100 * refVh,
          // outline: "1px solid pink",  // for debugging
        }
      ]}
      {...poRest}
    >
      {chartTitle && 
        <div className="title"
          css={{
            padding: "1.2em 6em",
            fontSize: "2rem",
            fontWeight: theme.font.weight.normal,
            textAlign: "center",
            color: theme.color.neonBlue,
          }}
        >{chartTitle}</div>
      }

      {children}

      {chartFooter && 
        <div className="footer"
          css={{
            marginTop: "1em",
            padding: "0em 5em",
            fontSize: "1.2rem",
            textAlign: "start",
            width: "100%",
            color: theme.color.white,
          }}
        >{chartFooter}</div>
      }
    </div>
  );
});

export default BasicChartCont;
