/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';
import * as d3 from "d3";

import bitcoinIconUrl from "./assets/bitcoin-icon.svg";
import comdsIconUrl from "./assets/comds-icon.svg";
import goldIconUrl from "./assets/gold-icon.svg";
import nasdaqIconUrl from "./assets/nasdaq-icon.svg";
import type { ChartData } from "./chartDataType";

const legendIconUrls = {
  "Gold": goldIconUrl,
  "Nasdaq": nasdaqIconUrl,
  "Commodities": comdsIconUrl,
  "Bitcoin": bitcoinIconUrl,
};

const legendlabels = {
  "Gold": "Gold",
  "Nasdaq": "Nasdaq",
  "Commodities": "Commodities",
  "Bitcoin": "Bitcoin",
};

type MarkData = ChartData;
type Props = ComponentProps<"div"> & {
  marks: d3.Selection<SVGElement, MarkData, d3.BaseType, any>
};

const Legend = (po: Props) => {
  if (po.marks.empty()) return null;
  
  const legendToMark = new Map<HTMLElement, SVGElement>();

  const onLegendItemClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    const legendEl = evt.currentTarget as HTMLElement;
    const markEl = legendToMark.get(legendEl)!;
    legendEl.classList.toggle("grey-out");
    markEl.classList.toggle("grey-out");
  };

  return (
    <div className={["legend"].join(" ")}
      css={{
        width: "100%",
        padding: "1.5rem 8% 1rem 15%",
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(5rem, auto))",
          columnGap: "3rem",
          rowGap: "1rem",
        }}
      >
        {po.marks.nodes().map( (n, idx) => {
          const name = d3.select<SVGElement, MarkData>(n).datum().yKeys[idx] as
            keyof typeof legendIconUrls;

          return (
            <div key={name} ref={ el => legendToMark.set(el!, n) }
              onClick={onLegendItemClick}
              className={["legend-item"].join(" ")}
              css={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.3rem",

                "&.grey-out img": {filter: `grayscale(95%)`}
              }}
            >
              <img src={legendIconUrls[name]}
                alt={`${name} legend icon`}
                css={{
                  width: "3.5rem",
                  height: "3.5rem",
                  marginRight: "1rem",
                }}
              />
              <span>{legendlabels[name]}</span>
            </div>
          );
        })}
      </div>
      
    </div>
  );
};

export default Legend;
