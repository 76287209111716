/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import * as d3 from "d3";

import theme from "../../../../theme";
import dataCsvUrl from "./data/figure1.csv";
import Legend from "./Legend";

const aspectRatio = 1.5/1;
const width = theme.media.screen.refDesktopWidth * 0.32;
const height = width / aspectRatio;
const margin = {
  top: height*0.12, 
  bottom: width*0.1, 
  left: width*0.2, 
  right: width*0.1, 
};
export const lineColors = {
  "Gold": "#c69c6dff",
  "Nikkei": "#ff7bacff",
  "Nasdaq": "#3fa9f5ff",
  "Commodities": "#f7931eff",
  "Chinese Equities": "#9e005dff",
  "FANGs": "#fcee21ff",
  "Bitcoin": "#00ffffff",
};

const taglines = {
  "Gold": "Honest Money",
  "Nikkei": "Japanese Economic Miracle",
  "Nasdaq": "Dot-com Revolution",
  "Commodities": "Supercycle",
  "Chinese Equities": "A Billion Chinese Consumers",
  "FANGs": "Secular Growth",
  "Bitcoin": "Crypto-storm",
};

const fetchAndPrepData = async () => {
  const data = await d3.csv(dataCsvUrl, d3.autoType);
  const years = data.map(d => +d[data.columns[0]]);

  const interestedCols = data.columns.slice(1);
  const series = interestedCols.reduce( 
    (acc, col) => {
      acc.push({ name: col, values: []});
      return acc;
    }, []);

  data.forEach( d => {
    series.forEach( ({name, values}) => {
      values.push(d[name]);
    });
  });

  const reshapedData = {
    xAxisLabel: "Years from start of regime change",
    yAxisLabel: "Price = 100 in year 1 of boom, log scale",
    series,
    years,
  };

  return reshapedData;
};

const buildChart = (rootEl, data) => {
  const root = d3.select(rootEl);

  // scales
  const x = d3.scaleLinear()
    .domain(d3.extent(data.years))
    .range([ margin.left, width - margin.right ]);

  const y = d3.scaleLog()
    .domain([10, 1e6])
    .range([ height - margin.bottom, margin.top ]);

  // x axis
  root.append("g")
      .attr("class", "axis x-axis")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(x).ticks(5).tickSizeOuter(0))
      .call(g => g.selectAll(".domain").remove())
      .call(g => g.selectAll(".tick line")
          .attr("stroke-opacity", 0.2)
      )
    // add label
    .append("text")
      .attr("class", "label")
      .text(data.xAxisLabel)
      .attr("text-anchor", "middle")
      .attr("x", margin.left + (width - margin.left - margin.right) / 2)
      .attr("y", margin.bottom*0.8)
      .attr("transform", "rotate(0)")
      .attr("fill", theme.color.white)
      .attr("font-weight", theme.font.weight.light);

  // y axis
  root.append("g")
      .attr("class", "axis y-axis")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(y)
          .ticks(4, ",.0f")
          .tickSize(-(width - margin.left-  margin.right))
          .tickSizeOuter(0)
      )
      .call(g => g.selectAll(".domain").remove())
      .call(g => g.selectAll(".tick line")
          .attr("stroke-opacity", 0.2)
      )
    .append("foreignObject")
        .attr("class", "label")
        .attr("x", "-10rem")
        .attr("width", "10rem")
        .attr("height", "3rem")
    .append("xhtml:div")
        .attr("style", "text-align: end; font-family: amplitude")
        .text(data.yAxisLabel);

  // tagline
  root.append("g")
      .attr("class", "tagline")
      .attr("transform", `translate(${width*0.55}, ${height*0.35})`)
    .append("text")
      .attr("text-anchor", "middle");

  // draw the lines
  root.selectAll(".line")
    .data(data.series, d => d.name)
    .join(enter => {
      const line = d3.line()
        .defined(d => d != null)
        .x( (_d, idx) => x(data.years[idx]))
        .y( d => y(d));

      const paths = enter.append("path")
          .attr("class", "line")
          .classed("glow", d => d.name === "Bitcoin")
          .attr("fill", "none")
          .attr("color", d => lineColors[d.name])
          .attr("stroke", d => lineColors[d.name])
          .attr("stroke-width", 1.5)
          .attr("d", d => line(d.values));

      // hidden on creation by "stroke-dashoffset"
      paths.each(function (d) { d.pathLength = this.getTotalLength(); })
          .attr("stroke-dasharray", d => d.pathLength)
          .attr("stroke-dashoffset", d => d.pathLength);
      return paths;
    });
};

/**
 * @param {object} po 
 * @param {string} [po.className]
 * @param {number} po.chartState
 * @returns React.ReactElement
 */
export const Chart = (po) => {
  const {chartState, className} = po;
  const rootRef = useRef(null);
  const dataRef = useRef(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isChartReady, setIsChartReady] = useState(false);
  const marks = d3.select(rootRef.current).selectAll(".line");

  useEffect(() => {(async () => {
    dataRef.current = await fetchAndPrepData();
    setIsDataReady(true);
  })()}, []);

  useEffect(() => {
    if (isDataReady && !isChartReady) {
      buildChart(rootRef.current, dataRef.current);
      setIsChartReady(true);
    }
  }, [isDataReady, isChartReady]);

  useEffect(() => {
    if (!isChartReady) return;

    const root = d3.select(rootRef.current);

    const assetsInView = dataRef.current.series
      .slice(0, chartState).map(s => s.name);
  
    // show chart lines according the scroll prog
    root.selectAll(".line")
        .classed("show", d => assetsInView.includes(d.name));
  
    // show tag line according the scroll prog
    if (assetsInView.length <= 0 || chartState > Object.keys(taglines).length) {
      root.selectAll(".tagline text")
          .text("")
          .attr("fill", "none");
    } else {
      root.selectAll(".tagline text")
          .text(`"${taglines[assetsInView[assetsInView.length-1]]}"`)
          .attr("font-size", "1.3em")
          .attr("font-weight", theme.font.weight.normal)
          .attr("fill", lineColors[assetsInView[assetsInView.length-1]]);
    }
  }, [isChartReady, chartState]);

  return (
    <div className={[
      "chart-cont",
      className,
    ].join(" ")}
      css={{
        display: "flex",
        flexDirection: "column",
        width:"100%",
        height:"100%",

        // outline: "1px solid red",  // for debugging
      }}
    >
      <svg ref={rootRef}
        className={["graph"].join(" ")}
        width="100%" height="75%"
        viewBox={`0 0 ${width} ${height}`}
        css={{
          ".line": {
            transition: "stroke-dashoffset 0.5s ease",
  
            "&.show": {
              strokeDashoffset: "0",
            },
            "&.glow": {
              filter: "drop-shadow(0 0 2px)",
            },
          },
          "text": {
            fontFamily: theme.font.family.main,
          },
          ".grey-out": {
            opacity: 0.2,
          },
        }}
      />
      <Legend marks={marks}/>
    </div>
  );
};

export default Chart;
