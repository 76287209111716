/** @jsxImportSource @emotion/react */
import { 
  ComponentProps, useEffect, useRef,
} from 'react';

import theme from "../../theme";

// the prog can be updated by 2 methods
// 1. pass the progList as props
// 2. calling the update fn passed in updateProgListRefCb
export type Props = ComponentProps<"div"> & {
  progList: number[],
  elList: (HTMLElement | null)[],
  updateProgListRefCb: ( fn: (progList: number[]) => void ) => void,
};

const NavIndicator = (po: Props) => {
  const {className, progList, elList, updateProgListRefCb} = po;
  const indiElListRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    // pass an update function to the caller
    // a work around to trigger direct update of (child) component 
    // without causing the whole parent to refresh
    updateProgListRefCb( (progList: number[]) => {
      progList.forEach((prog, idx) => {
        indiElListRef.current[idx]?.setAttribute("stroke-dashoffset", `${1-prog}`);
      });
    });
  }, [updateProgListRefCb])

  return (
    <div className={["nav-indicator", className].join(" ")}
      css={{
        display: "grid",
        gridTemplate: `repeat(${elList.length}, 1fr) / 1fr`,
        width: "2rem",
        maxHeight: window.innerHeight/2,
      }}
    >
      {elList.map((el, idx) => 
        <svg key={idx}
          width="100%" height="100%"
          viewBox={`0 0 ${16} ${16}`}
          onClick={() => el?.scrollIntoView()}
        >
          {/* center solid circle */}
          <circle
            cx="8" cy="8" r="2.5" 
            fill={theme.color.white}
            opacity="0.8"
          />

          {/* animated ring */}
          <circle ref={el => indiElListRef.current.push(el as unknown as HTMLElement)}
            cx="8" cy="8" r="5" 
            pathLength="1"
            fill="none"
            stroke={theme.color.white}
            strokeWidth="1.5"
            opacity="0.8"
            transform = "rotate(-90, 8, 8)"
            // setting the gap large than stroke (2 : 1 in this case)
            // such that the next/previous stroke will not leak at the endpoint
            // 1:1 will leak and won't work
            strokeDasharray="1 2"
            strokeDashoffset={1 - (progList[idx] ?? 0)}

            css={{
              transition: "all 0.5s ease",
            }}
          />
        </svg>
      )}
    </div>
  );
}

export default NavIndicator;
