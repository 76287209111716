/** @jsxImportSource @emotion/react */
import { 
  ComponentProps, useState, useRef 
} from 'react';

import theme from "../../../../../../theme";
import BasicNarration from '../../../../components/BasicNarration/BasicNarration';
import BasicChartCont from '../../../../components/BasicChartCont/BasicChartCont';
import Table from "./Table";
import fingerClickIconUrl from "./assets/finger-click-icon.svg";

export type Props = ComponentProps<"div"> & {
};

const NarratedTable = (po: Props) => {
  const [isRedGreenColoring, setIsRedGreenColoring] = useState(false);

  return  (
    <div
      css={[
        theme.mixin.sectionBaseStyles,
        {
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }
      ]}
    >
      <div className={["narration-container"].join(" ")}>
        <BasicNarration>
          <p>In a portfolio context, measured over a five year sample (top half of table), cryptocurrencies co{"\u2011"}movement with all markets remains low and seems to highlight their potential diversification value.</p>
          <p>Indeed, Bitcoin's correlation coefficients range from 0 to 0.2 and would seem to position it better than the Yen or Gold for hedging purposes.</p>
        </BasicNarration>

        <BasicNarration
          onEnter={() => setIsRedGreenColoring(true)}
          onExit={() => setIsRedGreenColoring(false)}
        >
          <p>However, the mainstreaming of cryptocurrencies - particularly with retail investors - appears to be raising its correlation with all cyclical assets (Equities, Credit, Commodities, the EM complex). If sustained, this development could erode diversification value over time.</p>
          <p>While many pairwise correlations remain moderate (around 0.4) even after their rise, this trend bears watch.</p>
        </BasicNarration>
      </div>

      <BasicChartCont
        css={{flexGrow: 1}}
        chartTitle="Table 1: Correlation of weekly returns over past five years and past year"
        chartFooter={(
          <>
            <div css={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              fontStyle: "italic",
              fontSize: "1.5rem",
              whiteSpace: "pre-wrap",
            }}>
              <img src={fingerClickIconUrl} alt=""
                css={{
                  width: "1.8em",
                  height: "1.8em",
                  position: "relative",
                  bottom: "0.3em",
                }}
              />
              Click to toggle between 
              <strong> past-5-years </strong>, 
              <strong> last-year </strong> and 
              <strong> combined </strong> 
              view per column in the chart
            </div>

            <p>Source: J.P. Morgan, Bloomberg Finance L.P.</p>
          </>
        )}
      >
        <Table isRedGreenColoring={isRedGreenColoring} />
      </BasicChartCont>

    </div>
  );
}

export default NarratedTable;
