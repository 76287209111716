/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import * as d3 from "d3";

import theme from "../../../../../../theme";
import dataCsvUrl from "../../data/figure2.csv";
import Legend from "./Legend";

const aspectRatio = 1.5/1;
const width = theme.media.screen.refDesktopWidth * 0.35;
const height = width / aspectRatio;
const margin = {
  top: height*0.05,
  bottom: width*0.05,
  left: width*0.1,
  right: width*0.1,
};

const fetchAndPrepData = async () => {
  const data = await d3.csv(dataCsvUrl);
  const reshapedData = {
    xKey: data.columns[0],
    y1Key: data.columns[1],
    y2Key: data.columns[2],
    xtitle: data.columns[0],
    y1title: data.columns[1],
    y2title: data.columns[2],
    y1Color: "#f15a24ff",
    y2Color: "#fcee21ff",
    vertHlColor: "#cccccc55",
    values: data.slice(),
  };

  // type conversions
  reshapedData.values.map(d3.autoType);
  reshapedData.values = reshapedData.values.map( d => ( 
    {...d, [data.columns[0]]: new Date(
      d[data.columns[0]].split("/").reverse().map(Number)
    )} 
  ));

  return reshapedData;
};

const buildChart = (rootEl, data) => {
  const root = d3.select(rootEl);

  // scales
  const x = d3.scaleTime()
    .domain(d3.extent(data.values.map( d=> d[data.xKey] )))
    .range([ margin.left, width - margin.right ]);

  const y1 = d3.scaleLinear()
    .domain([-4, 4])
    .range([ height - margin.bottom, margin.top ]);
  
  const y2 = d3.scaleLinear()
    .domain([0, 60])
    .range([ height - margin.bottom, margin.top ]);

  const color = d3.scaleOrdinal([data.y1Key, data.y2Key], [data.y1Color, data.y2Color]);

  // x axis
  root.append("g")
      .attr("class", "axis x-axis")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(g => g.selectAll(".tick line").remove())
      .call(d3.axisBottom(x)
        .ticks(7)
        .tickSize(12)  // increase spacing btw tick label and domain axis
        .tickFormat(d3.timeFormat("%y"))
      )
      .call(g => g.selectAll(".domain").remove())
      .call(g => g.selectAll(".tick line").remove())

  // y1 axis
  root.append("g")
      .attr("class", "axis y-axis y1-axis")
      .attr("transform", `translate(${margin.left}, 0)`)
      .attr("color", color(data.y1Key))
      .call(d3.axisLeft(y1)
        .tickFormat(d => d3.format(".1")(d) + "%")
        .tickSize(-(width - margin.left - margin.right))
        .tickSizeOuter(0)
      )
      .call(g => g.selectAll(".domain").remove())
      .call(g => g.selectAll(".tick line")
          .attr("stroke", "white")
          .attr("opacity", 0.1)
      )

  // y2 axis
  root.append("g")
      .attr("class", "axis y-axis y2-axis")
      .attr("transform", `translate(${width - margin.right}, 0)`)
      .attr("color", color(data.y2Key))
      .call(d3.axisRight(y2)
        .tickValues([0, 10, 20, 30, 40, 50, 60])
        .tickFormat(d => d3.format(".3")(d) + "%")
      )
      .call(g => g.selectAll(".domain").remove())
      .call(g => g.selectAll(".tick line").remove())

  // vertical highlight
  root.append("g")
      .attr("class", "vertical-highlight")
      .attr("color", data.vertHlColor)
      .call(g => g.append("rect")
          .attr("x", x(new Date(2000, 3, 1)))
          .attr("y", margin.top)
          .attr("width", x(new Date(2002, 4, 1)) - x(new Date(2000, 3, 1)))
          .attr("height", height - margin.top - margin.bottom)
      )
      .call(g => g.append("rect")
          .attr("x", x(new Date(2007, 5, 1)))
          .attr("y", margin.top)
          .attr("width", x(new Date(2010, 3, 1)) - x(new Date(2007, 5, 1)))
          .attr("height", height - margin.top - margin.bottom)
      )
      .call(g => g.append("rect")
          .attr("x", x(new Date(2019, 5, 1)))
          .attr("y", margin.top)
          .attr("width", x(new Date(2020, 6, 1)) - x(new Date(2019, 5, 1)))
          .attr("height", height - margin.top - margin.bottom)
      )

  // draw line 1
  root.selectAll(".line1")
    .data([data], d => d.y1Key)
    .join(enter => {

      const line = d3.line()
        .defined(d => d != null)
        .x( d => x(d[data.xKey]) )
        .y( d => y1(d[data.y1Key]) );

      const paths = enter.append("path")
          .attr("class", "line line1")
          .attr("fill", "none")
          .attr("stroke", d => color(d.y1Key))
          .attr("stroke-width", 1.5)
          .attr("d", d => line(d.values));

      // hidden on creation by "stroke-dashoffset"
      paths.each(function (d) { d.pathLength = this.getTotalLength(); })
          .attr("stroke-dasharray", d => d.pathLength)
          .attr("stroke-dashoffset", d => d.pathLength);
      return paths;
    });

  // draw line 2
  root.selectAll(".line2")
    .data([data], d => d.y2Key)
    .join(enter => {
      const line = d3.line()
        .defined(d => d != null)
        .x( d => x(d[data.xKey]) )
        .y( d => y2(d[data.y2Key]) );

      const paths = enter.append("path")
          .attr("class", "line line2")
          .attr("fill", "none")
          .attr("stroke", d => color(d.y2Key))
          .attr("stroke-width", 1.5)
          .attr("d", d => line(d.values));

      // hidden on creation by "stroke-dashoffset"
      paths.each(function (d) { d.pathLength = this.getTotalLength(); })
          .attr("stroke-dasharray", d => d.pathLength)
          .attr("stroke-dashoffset", d => d.pathLength);
      return paths;
    });
};

/**
 * @param {object} po 
 * @param {string} [po.className]
 * @param {bool} po.isActive
 * @returns React.ReactElement
 */
export const Chart = (po) => {
  const {className, isActive} = po;
  const rootRef = useRef(null);
  const dataRef = useRef(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isChartReady, setIsChartReady] = useState(false);
  const marks = d3.select(rootRef.current).selectAll(".line");

  useEffect(() => {(async () => {
    dataRef.current = await fetchAndPrepData();
    setIsDataReady(true);
  })()}, []);

  useEffect(() => {
    if (isDataReady && !isChartReady) {
      buildChart(rootRef.current, dataRef.current);
      setIsChartReady(true);
    }
  }, [isDataReady, isChartReady]);

  useEffect(() => {
    // show chart lines according the scroll prog
    d3.select(rootRef.current).selectAll(".line")
      .classed("show", isActive);
  }, [isActive])

  return (
    <div className={[
      "chart-cont",
      className,
    ].join(" ")}
      css={{
        display: "flex",
        flexDirection: "column",
        width:"100%",
        height:"100%",

        // outline: "1px solid red",  // for debugging
      }}
    >
      <svg ref={rootRef}
        className={["graph"].join(" ")}
        width="100%" height="100%"
        viewBox={`0 0 ${width} ${height}`}
        css={{
          ".line": {
            transition: "stroke-dashoffset 1.0s ease",
  
            "&.show": {
              strokeDashoffset: "0",
            },
          },
        }}
      />
      <Legend marks={marks}/>
    </div>
  );
};

export default Chart;
