/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import * as d3 from "d3";

import type { ChartData } from "./chartDataType";

type MarkData = ChartData
type Props = ComponentProps<"div"> & {
  marks: d3.Selection<SVGElement, MarkData, d3.BaseType, any>
}

const legendContCss = css({
  display: "flex",
  flexWrap: "wrap",
  rowGap: "1rem",
  marginTop: "2rem",
});
const legendItemCss = css({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginLeft: "2rem",
  fontSize: "1.5rem",

  "&.grey-out img": {filter: `grayscale(95%)`}
});

const circleSvgCss = css({
  display: "block",
  objectFit: "contain",
  width: "2rem",
  marginRight: "1rem",
});

const Legend = (po: Props) => {
  if (po.marks.empty()) return null;
  
  const gD = () => po.marks.datum();

  return (
    <div className="legend" css={legendContCss}>
      <div className="legend-item" css={legendItemCss}>
        <svg viewBox="-3 -3 6 6" css={circleSvgCss}>
          <circle r="2" strokeWidth="1" stroke={gD().y1Color} fill="none" />
        </svg>
        
        {gD().y1title}
      </div>

      <div className="legend-item" css={legendItemCss}>
        <svg viewBox="-3 -3 6 6" css={circleSvgCss}>
          <circle r="2" strokeWidth="1" stroke={gD().y2Color} fill="none" />
        </svg>
        
        {gD().y2title}
      </div>
    </div>
  );
};

export default Legend;
