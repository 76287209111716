/** @jsxImportSource @emotion/react */
import { useEffect, useState, useMemo } from 'react';
import * as d3 from "d3";
import { useTable } from "react-table";

import theme from "../../../../../../theme";
import past5YearsCsvUrl from "./data/past-5-years.csv";
import lastYearCsvUrl from "./data/last-year.csv";

enum ColumnState { past5, last, combined };

const headerLabelMap = {
  "S&P 500": "S&P 500",
  "USTs": "USTs",
  "US HG Credit": "US HG \xAD Credit",
  "EM Locals": "EM Locals",
  "TIPS": "TIPS",
  "USD trade-wtd": "USD \xAD trade-wtd",
  "Commodities": "Commo\xADdities",
  "Gold": "Gold",
  "Yen cash": "Yen cash",
  "Bitcoin": "Bitcoin",
};

const headerClassMap = {
  "S&P 500": "snp-500",
  "USTs": "usts",
  "US HG Credit": "us-hg-credit",
  "EM Locals": "em-locals",
  "TIPS": "tips",
  "USD trade-wtd": "usd-trade-wtd",
  "Commodities": "commodities",
  "Gold": "gold",
  "Yen cash": "yen-cash",
  "Bitcoin": "bitcoin",
};

const footerStateMap = {
  [ColumnState.past5]: "\u2022\u2022\u2022\u2022\u2022",
  [ColumnState.last]: "\u2022",
  [ColumnState.combined]: "\u2022\u2022\u2022\u2022\u2022 | \u2022",
};

const fetchAndPrepData = async () => {
  const past5YearsJson = await d3.csv(past5YearsCsvUrl, d3.autoType);
  const lastYearJson = await d3.csv(lastYearCsvUrl, d3.autoType);

  return {past5YearsJson, lastYearJson};
};

type Props = {
  isRedGreenColoring: boolean,
};

const Table = (po: Props) => {
  const {isRedGreenColoring} = po;
  const [past5YearsJson, setPast5YearsJson] = useState<any>(null);
  const [lastYearJson, setLastYearJson] = useState<any>(null);

  // async load data from csv files
  useEffect(() => {(async () => {
    // parentheses needed here, see
    // https://stackoverflow.com/a/27386370/5553468
    const {past5YearsJson, lastYearJson} = await fetchAndPrepData();
    
    setPast5YearsJson(past5YearsJson);
    setLastYearJson(lastYearJson);
  })()}, []);

  useEffect(() => {
    setColumnsState(prev => Object.fromEntries(
      Object.entries(prev).map(([key, _]) => [
        key, 
        !isRedGreenColoring ? ColumnState.past5 : ColumnState.last,
      ]))
    );
  }, [isRedGreenColoring]);

  const [columnsState, setColumnsState] = useState<{[key: string]: ColumnState}>({
    "S&P 500": ColumnState.past5,
    "USTs": ColumnState.past5,
    "US HG Credit": ColumnState.past5,
    "EM Locals": ColumnState.past5,
    "TIPS": ColumnState.past5,
    "USD trade-wtd": ColumnState.past5,
    "Commodities": ColumnState.past5,
    "Gold": ColumnState.past5,
    "Yen cash": ColumnState.past5,
    "Bitcoin": ColumnState.past5,
  });
  const setToNextColumnState = (colId: string) => {
    setColumnsState(prev => ({
      ...prev, 
      [colId]: (prev[colId] + 1) % 3
    }));
  };

  const combinedColumns = useMemo(() => {
    if (past5YearsJson == null || lastYearJson == null) return [];

    return past5YearsJson.columns.map( (key: string, idx: number) => ({
      Header: key,
      accessor: key,
      id: key,
    }));
  }, [past5YearsJson, lastYearJson]);

  const combinedData = useMemo(() => {
    if (past5YearsJson == null || lastYearJson == null) return [];
    
    return past5YearsJson.map( (row: any, rowIdx: any) => Object.fromEntries(
      Object.entries(row).map( ([key, value]) => [
        key, 
        {
          past5: value,
          last: lastYearJson[rowIdx][key],
        }
      ])
    ));
  }, [past5YearsJson, lastYearJson]);

  const tableInstance = useTable({columns: combinedColumns, data: combinedData});

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    columns,
    rows,
    footerGroups,
    prepareRow,
  } = tableInstance

  return (
    <div css={{padding: "0 0 0 2rem"}}>
      <table {...getTableProps()}
        className={[
          isRedGreenColoring ? "is-red-green-coloring" : null,
        ].join(" ")}
        css={{
          tableLayout: "fixed",
          width: "100%",
          borderCollapse: "collapse",

          marginBottom: "2rem",

          fontSize: "1.3rem",
          textAlign: "center",

          "th, td": {
            fontWeight: theme.font.weight.light,

            padding: 0,
          },

          "tbody tr:nth-of-type(even)": {
            backgroundColor: "#FFFFFF22",
          },

          "thead tr": {
            borderBottom: `1px solid ${theme.color.white}`,
          },
          "tfoot tr": {
            borderTop: `1px solid ${theme.color.lightGrey}`,
          },

          "col.bitcoin": {
            background: "#FFFFFF55",
          },

          // adjusting column visibility based on columnsState
          [[
            `td .cell-div.column-state-${ColumnState.past5} .past5`,
            `td .cell-div.column-state-${ColumnState.last} .last`,
            `td .cell-div.column-state-${ColumnState.combined} div`,
          ].join(",")]: {
            width: "auto",
            padding: "0 0.5rem",
            opacity: 1,
          },

          "&.is-red-green-coloring": {
            "td .cell-div.red": {background: theme.color.cellRed},
            "td .cell-div.green": {background: theme.color.cellGreen},
          },
        }}
      >
        <colgroup>
          {/* the empty leftmost cell */}
          <col css={{width: "10rem"}} />
          {columns.map( column => {
            const colId = column.id as keyof typeof headerClassMap;
            return (<col key={colId} className={[headerClassMap[colId]].join(" ")} />)
          })}
        </colgroup>

        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {/* the empty leftmost cell */}
              <th></th>

              {headerGroup.headers.map( (column, colIdx) => (
                <th {...column.getHeaderProps()}
                  onClick={() => setToNextColumnState(column.id)}
                >
                  {headerLabelMap[column.Header as keyof typeof headerLabelMap]}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIdx) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <th scope="row">{columns[rowIdx].render("Header")}</th>

                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    <div 
                      className={[
                        "cell-div",
                        `column-state-${columnsState[cell.column.id]}`,

                        cell.value.last != null && cell.value.past5 != null
                          && cell.value.past5 !== 1
                          && (cell.value.last >= cell.value.past5) ? "red" : null,

                        cell.value.last != null && cell.value.past5 != null
                          && cell.value.past5 !== 1
                          && (cell.value.last < cell.value.past5) ? "green" : null,
                      ].join(" ")}

                      onClick={() => setToNextColumnState(cell.column.id)}

                      css={{
                        display: "flex", 
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        padding: "0.5rem",
                        transition: "all 0.2s ease",

                        "div": {
                          width: 0,    // default hidden
                          opacity: 0,  // default hidden
                          whiteSpace: "inherit",
                          overflow: "inherit",
                          transition: "all 0.2s ease",
                        }
                      }}
                    >
                      <div className={["past5"].join(" ")}
                      >{cell.value.past5}</div>

                      <div className={["last"].join(" ")}
                      >{cell.value.last}</div>
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>

        <tfoot>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              <th scope="row">Time Range</th>

              {group.headers.map(column => (
                <td {...column.getFooterProps()}
                  onClick={() => setToNextColumnState(column.id)}
                >
                  {footerStateMap[columnsState[column.id]]}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
}

export default Table;
