/** @jsxImportSource @emotion/react */
import { ComponentProps, useState } from 'react';

import { refVh } from "../../../../../../theme";
import { ScrollyStep } from "../../../../../scrolly";
import splashUrl from "./assets/two-crite-1-splash.svg";

export type Props = ComponentProps<"div"> & {
};

const Intro = (po: Props) => {
  const [isActive, setIsActive] = useState(false);

  return  (
    <ScrollyStep
      onEnter={() => setIsActive(true)}
      onExit={() => setIsActive(false)}
    >
      <div css={{
        height: 180 * refVh,
        padding: `${50*refVh}px 8rem 0 8rem`,
      }}>
        <div
          className={["splash"].join(" ")}
          css={{
            position: "sticky",
            top: "50%",
            transform: "translate(0, -50%)",

            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "start",
            gap: "1rem",
          }}
        >
          <img src={splashUrl} alt=""
            className={[
              isActive ? "active" : null,
            ].join(" ")}
            css={{
              position: "absolute",
              top: 0,
              left: 0,
              maxWidth: "100%",

              transition: "all 1s ease",
              transform: "translate(-100%, -50%)",
              opacity: 0,

              "&.active": {
                transform: "translate(0, -50%)",
                opacity: 1,
              },
            }}
          />

          {/* text block */}
          <div>
            {/* placeholder */}
          </div>
        </div>
      </div>
    </ScrollyStep>
  );
}

export default Intro;
