/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';

import theme, { refVh } from "../../../../../../theme";
import { ScrollyStep } from "../../../../../scrolly";
import n1markDiskUrl from "../../assets/n1-mark-disk.svg";
import n2markDiskUrl from "../../assets/n2-mark-disk.svg";
import n3markDiskUrl from "../../assets/n3-mark-disk.svg";

export type Props = ComponentProps<"div"> & {
  isActive: boolean,
  onEnter?: (dir: "up"|"down") => void,
  onExit?: (dir: "up"|"down") => void,
};

const Step02 = (po: Props) => {
  const {isActive, onEnter, onExit} = po;

  return (
    <ScrollyStep
      onEnter={onEnter}
      onExit={onExit}
    >
      <div css={{
        height: 180 * refVh,
        padding: `${50*refVh}px 8rem 0 8rem`,
      }}>
        <div
          className={["splash"].join(" ")}
          css={[
            theme.mixin.narrationBaseStyles,
            {
              position: "sticky",
              top: "50%",
              transform: "translate(0, -50%)",

              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }
          ]}
        >
          <h2 css={{
            fontSize: "3.5rem",
            fontWeight: theme.font.weight.normal,
            textShadow: `0 0 0.5em ${theme.color.white}`,
            textAlign: "center",
          }}>
            <span css={{fontSize: "6em", marginRight: "1rem"}}>
              3
            </span>
            reasons:
          </h2>
        </div>
      </div>
    </ScrollyStep>
  );
}

export default Step02;
