/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useMemo } from 'react';
import * as d3 from "d3";
import { useTable, TableInstance } from "react-table";

import theme from "../../../../../../theme";
import table2CsvUrl from "./data/table2.csv";


const fetchAndPrepData = async () => {
  const table2Json = await d3.csv(table2CsvUrl, d3.autoType);

  return {table2Json};
};

type Props = {
  prog: number,
};

const Table = (po: Props) => {
  const {prog} = po;
  const [table2Json, setTable2Json] = useState<any>([]);
  const isGreenHighlight = prog >= 0.7 ? true : false;

  // async load data from csv files
  useEffect(() => {(async () => {
    const {table2Json} = await fetchAndPrepData();
    
    setTable2Json(table2Json);
  })()}, []);

  const rowIdxToProgScale = useMemo(() => (
    d3.scaleLinear()
      .domain([0, table2Json.length-1])
      .range([ 0, 0.5 ])
  ), [table2Json]);

  const table2JsonCols = useMemo(() => {
    if (table2Json.length === 0) return [];

    return table2Json.columns.map( (key: string, idx: number) => ({
      Header: idx === 0 ? "" : key,
      accessor: key,
      id: key,
    }));
  }, [table2Json]);

  const tIns = useTable({columns: table2JsonCols, data: table2Json});

  // Note: 
  // using 'tIns' as dependency does not work,
  // probably because tIns is a hook so its value/hash
  // does not change between render ticks
  const {
    Table: cachedTable, 
    els: {tableEl: cachedTableEl, trElList: cachedTrElList},
  } = useMemo(() => tableBuilder(tIns), [table2Json]);

  cachedTableEl && cachedTableEl.classList.toggle( "is-green-highlight", isGreenHighlight);

  cachedTrElList.forEach( (el, idx) => {
    rowIdxToProgScale(idx) <= prog 
      ? el.classList.toggle("show", true)
      : el.classList.toggle("show", false)
  });

  return (
    <>
      {cachedTable}
    </>
  );
}

const tableBuilder = (tIns: TableInstance) => {  
  const trElList: HTMLElement[] = [];
  const els = {
    tableEl: null as HTMLElement | null,
    trElList,
  }

  const Table = (po: {tIns: TableInstance}) => {
    const {tIns} = po;
    
    return (
      <div css={{padding: "0 0 0 2rem"}}>
        <table {...tIns.getTableProps()}
          ref={el => els.tableEl = el as HTMLElement}
          css={{
            tableLayout: "fixed",
            width: "100%",
            borderCollapse: "collapse",

            marginBottom: "2rem",

            fontSize: "1.3rem",
            textAlign: "center",

            "th, td": {
              fontWeight: theme.font.weight.light,

              padding: 0,
            },

            "tbody tr:nth-of-type(even)": {
              backgroundColor: "#FFFFFF22",
            },

            "thead tr": {
              borderBottom: `1px solid ${theme.color.white}`,
            },
            "tfoot tr": {
              borderTop: `1px solid ${theme.color.lightGrey}`,
            },

            "&.is-green-highlight": {
              "td.green": {background: theme.color.cellGreen},
            },
          }}
        >
          <colgroup>
            {tIns.columns.map( column => {
              return (<col key={column.id} />);
            })}
          </colgroup>

          <thead>
            {tIns.headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map( (column, colIdx) => (
                  <th {...column.getHeaderProps()}
                  >
                    {column.Header}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          
          <tbody {...tIns.getTableBodyProps()}>
            {tIns.rows.map((row) => {
              tIns.prepareRow(row);
              return (
                <tr {...row.getRowProps()}
                  ref={el => trElList.push(el as HTMLElement)}
                  css={{
                    "td > div": {
                      minHeight: 0,
                      maxHeight: 0,
                      overflow: "hidden",
                      transition: "all 0.5s ease",
                    },
                    "&.show td > div": {
                      minHeight: "2rem",
                      maxHeight: "3rem",
                    },
                  }}
                >
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}
                      className={[
                        cell.value > 0 ? "green" : null,
                      ].join(" ")}

                      css={{
                        transition: "all 0.2s ease"
                      }}
                    >
                      <div>{
                        typeof cell.value === "number"
                          ? cell.value.toFixed(2)
                          : cell.value
                      }</div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )
  };
  
  return {
    Table: React.createElement(Table, {tIns}),
    els,
  };
}

export default Table;
