/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import * as d3 from "d3";
import { nanoid } from 'nanoid';

import theme from "../../../../../../theme";
import dataCsvUrl from "./data/figure3.csv";
import Legend from "./Legend";

const aspectRatio = 1.8/1;
const width = theme.media.screen.refDesktopWidth * 0.4;
const height = width / aspectRatio;
const margin = {
  top: height*0.2,
  bottom: width*0.1,
  left: width*0.16,
  right: width*0.1,
};
export const lineColors = {
  "Gold": "#c69c6dff",
  "Nasdaq": "#3fa9f5ff",
  "Commodities": "#f7931eff",
  "Bitcoin": "#00ffffff",
};

const fetchAndPrepData = async () => {
  const data = await d3.csv(dataCsvUrl, d3.autoType);

  const reshapedData = {
    xKey: data.columns[0],
    yKeys: data.columns.slice(1),
    xTitle: data.columns[0],
    yTitles: data.columns.slice(1),
    xAxisLabel: "Year",
    yAxisLabel: "Rolling 12-mo returns divided by rolling 1Y realized volatility",
    values: data.slice(),
  };

  // type conversions
  // Note: 
  // Excel might silently reformat data values
  // e.g. changing date from yyyy-mm-dd to dd-mm-yyyy
  reshapedData.values = reshapedData.values.map( d => {
    data.columns.slice(1).forEach( colKey => {
      d[colKey] = Number(d[colKey]);
    });

    return d;
  });

  reshapedData.values = reshapedData.values.map( d => ( 
    {...d, [data.columns[0]]: new Date(
      d[data.columns[0]].split("/").reverse().map(Number)
    )} 
  ));

  return reshapedData;
};

const buildChart = (rootEl, data) => {
  const root = d3.select(rootEl);

  // scales
  const x = d3.scaleTime()
    .domain(d3.extent(data.values.map( d=> d[data.xKey] )))
    .range([ margin.left, width - margin.right ]);

  const y = d3.scaleLinear()
    .domain([-4, 21])
    .range([ height - margin.bottom, margin.top ]);

  const color = d3.scaleOrdinal(Object.keys(lineColors), Object.values(lineColors));

  // x axis
  root.append("g")
      .attr("class", "axis x-axis")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(x)
        .tickSize(12)  // increase spacing btw tick label and domain axis
        .ticks(5)
        // .tickValues([new Date("2012-01-01"), new Date("2015-01-01"), new Date("2018-01-01"), new Date("2021-01-01")])
        .tickFormat(d3.timeFormat("%Y"))
      )
      .call(g => g.selectAll(".domain").remove())
      .call(g => g.selectAll(".tick line").remove())
    // add label
    .append("text")
      .attr("class", "label")
      .text(data.xAxisLabel)
      .attr("text-anchor", "middle")
      .attr("x", margin.left + (width - margin.left - margin.right) / 2)
      .attr("y", margin.bottom*0.8)
      .attr("transform", "rotate(0)")
      .attr("fill", theme.color.white)
      .attr("font-weight", theme.font.weight.light);

  // y axis
  root.append("g")
      .attr("class", "axis y-axis y1-axis")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(y)
        .tickValues([21, 16, 11, 6, 1, -4])
        .tickFormat(d => d3.format(".0f")(d) + "%")
        .tickSize(-(width - margin.left - margin.right))
        .tickSizeOuter(0)
      )
      .call(g => g.selectAll(".domain").remove())
      .call(g => g.selectAll(".tick line")
          .attr("stroke", "white")
          .attr("opacity", 0.1)
      )
    // add label
    .append("foreignObject")
        .attr("class", "label-cont")
        .attr("x", "-10rem")
        .attr("width", "10rem")
        .attr("height", "5.0rem")
    .append("xhtml:div")
        .attr("class", "label")
        .text(data.yAxisLabel);

  // define the clipping box for the lines
  const clipPathId = nanoid(6);
  root.append("defs")
    .append("clipPath")
      .attr("id", clipPathId)
    .append("rect")
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom)

  // draw the lines
  data.yKeys.forEach( (yKey, idx) => {
    const lnIdx = idx+1;

    root.selectAll(`.line${lnIdx}`)
    .data([data], d => d[yKey])
    .join(enter => {
      const line = d3.line()
        .defined(d => {
          const x = d[data.xKey];
          const y = d[yKey];
          if (x == null || Number.isNaN(d)) return false;
          if (y == null || Number.isNaN(d)) return false;
          // if (y > 21 ||  y < -4) return false;

          return true;
        })
        .x( d => x(d[data.xKey]) )
        .y( d => y(d[yKey]));

      const paths = enter.append("path")
          .attr("clip-path", `url(#${clipPathId})`)
          .attr("class", `line line${lnIdx}`)
          .classed("glow", () => yKey === "Bitcoin")
          .attr("fill", "none")
          .attr("stroke", _d => color(yKey))
          .attr("stroke-width", 1.5)
          .attr("d", d => line(d.values));

      // hidden on creation by "stroke-dashoffset"
      paths.each(function (d) { d.pathLength = this.getTotalLength(); })
          .attr("stroke-dasharray", d => d.pathLength)
          .attr("stroke-dashoffset", d => d.pathLength);
      return paths;
    });
  });
};

/**
 * @param {object} po 
 * @param {string} [po.className]
 * @param {bool} po.isActive
 * @returns React.ReactElement
 */
export const Chart = (po) => {
  const {className, isActive} = po;
  const rootRef = useRef(null);
  const dataRef = useRef(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isChartReady, setIsChartReady] = useState(false);
  const marks = d3.select(rootRef.current).selectAll(".line");

  useEffect(() => {(async () => {
    dataRef.current = await fetchAndPrepData();
    setIsDataReady(true);
  })()}, []);

  useEffect(() => {
    if (isDataReady && !isChartReady) {
      buildChart(rootRef.current, dataRef.current);
      setIsChartReady(true);
    }
  }, [isDataReady, isChartReady]);

  useEffect(() => {
    // show chart lines according the scroll prog
    d3.select(rootRef.current).selectAll(".line")
      .classed("show", isActive);
  }, [isActive])

  return (
    <div className={[
      "chart-cont",
      className,
    ].join(" ")}
      css={{
        display: "flex",
        flexDirection: "column",
        width:"100%",
        height:"100%",

        // outline: "1px solid red",  // for debugging
      }}
    >
      <svg ref={rootRef}
        className={["graph"].join(" ")}
        width="100%" height="100%"
        viewBox={`0 0 ${width} ${height}`}
        css={{
          ".line": {
            transition: "stroke-dashoffset 1.0s ease",
  
            "&.glow": {
              filter: "drop-shadow(0 0 2px)",
            },

            "&.show": {
              strokeDashoffset: "0",
            },
          },
          "text": {
            fontFamily: theme.font.family.main,
          },
          ".grey-out": {
            opacity: 0.2,
          },
          "foreignObject .label": {
            textAlign: "end",
            padding: "0 0.2rem",
            fontFamily: theme.font.family.main,
          }
        }}
      />
      <Legend marks={marks}/>
    </div>
  );
};

export default Chart;
