/** @jsxImportSource @emotion/react */
import { ComponentProps, useState } from 'react';

import theme from "../../../../theme";
import Intro from "./parts/Intro/Intro";
import Reason01 from "./parts/Reason01/Reason01";
import Reason02 from "./parts/Reason02/Reason02";
import Reason03 from "./parts/Reason03/Reason03";
import 
  DiskIndicator, { State as DiskIndicatorState } 
from "./components/DiskIndicator/DiskIndicator";

export type Props = ComponentProps<"div"> & {
};

const WhyCrypto = (po: Props) => {
  const [indicatorStates, setIndicatorStates] = useState([
    DiskIndicatorState.hidden,
    DiskIndicatorState.hidden,
    DiskIndicatorState.hidden,
  ]);

  return  (
    <div 
      className={["why-crypto", "section"].join(" ")}
      css={[
        theme.mixin.sectionBaseStyles,
      ]}
    >
      <DiskIndicator state={indicatorStates[0]} 
        bottomCss={"4rem"} leftCss={"calc(50% - 3.5rem)"}
      />
      <DiskIndicator state={indicatorStates[1]} 
        bottomCss={"4rem"} leftCss={"50%"}
      />
      <DiskIndicator state={indicatorStates[2]} 
        bottomCss={"4rem"} leftCss={"calc(50% + 3.5rem)"}
      />

      <Intro 
        onStep02Enter={(dir) => dir === "down" && setIndicatorStates([1,1,1])}
        onExit={(dir) => dir === "up" && setIndicatorStates([0,0,0])}
      />
      <Reason01 
        onEnter={(dir) => dir === "down" && setIndicatorStates([2,1,1])}
        onExit={(dir) => dir === "up" && setIndicatorStates([1,1,1])}
      />
      <Reason02 
        onEnter={(dir) => dir === "down" && setIndicatorStates([3,2,1])}
        onExit={(dir) => dir === "up" && setIndicatorStates([2,1,1])}
      />
      <Reason03 
        onEnter={(dir) => {
          dir === "down" && setIndicatorStates([3,3,2]);
          dir === "up" && setIndicatorStates([3,3,2]);
        }}
        onExit={(dir) => {
          dir === "up" && setIndicatorStates([3,2,1]);
          dir === "down" && setIndicatorStates([3,3,3]);
        }}
      />
    </div>
  );
}

export default WhyCrypto;
