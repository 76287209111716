/** @jsxImportSource @emotion/react */
import { ComponentProps } from "react";

import videoUrl from "./assets/title_animation_vp9_10KB_crf10.webm";
import bkgSrcUrl from "./assets/bg.png";

type Props = {
  className?: string,
  isShown: boolean,
};

const VideoBkg = (po: Props) => {
  const {className, isShown, ...poRest} = po;

  return (
    <div 
      className={[
        className,
      ].join(" ")}
      css={{
        width: "100%",
        height: "100%",
      }}
    >
      <img src={bkgSrcUrl} alt=""
        css={{
          position: "fixed",
          top: 0,
          left:0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />

      <video src={videoUrl} autoPlay muted loop
        className={[
          isShown ? "show" : null,
        ].join(" ")}
        css={{
          position: "fixed",
          top: 0,
          left:0,
          width: "100%",
          height: "100%",
          objectFit: "contain",

          opacity: 0,
          transition: "all 0.5s ease",

          "&.show": {
            opacity: 1,
          },
        }}
      />

    </div>
  )
}

export default VideoBkg;
