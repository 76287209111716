/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';

import Splash from "./Splash";
import NarratedChart from "./NarratedChart";
import NarratedTable from "./NarratedTable";

export type Props = ComponentProps<"div"> & {
};

const Criteria01 = (po: Props) => {

  return  (
    <div>
      <Splash />
      <NarratedChart />
      <NarratedTable />
    </div>
  );
}

export default Criteria01;
