/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';

import theme from "../../../../theme";
import Intro from "./parts/Intro/Intro";
import Criteria01 from "./parts/Criteria01/Criteria01";
import Criteria02 from "./parts/Criteria02/Criteria02";

export type Props = ComponentProps<"div"> & {
};

const TwoCriteria = (po: Props) => {

  return  (
    <div 
      className={["two-criteria", "section"].join(" ")}
      css={[
        theme.mixin.sectionBaseStyles,
      ]}
    >
      <Intro />
      <Criteria01 />
      <Criteria02 />
    </div>
  );
}

export default TwoCriteria;
