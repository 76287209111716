/** @jsxImportSource @emotion/react */
import { ComponentProps, useState } from 'react';
import { nanoid } from 'nanoid';

import theme, { refVh } from "../../../../../../theme";
import BasicNarration from '../../../../components/BasicNarration/BasicNarration';
import BasicChartCont from '../../../../components/BasicChartCont/BasicChartCont';
import n1MarkDiskUrl from "../../assets/n1-mark-disk.svg";
import Chart from "./Chart";

export type Props = ComponentProps<"div"> & {
  onEnter?: (dir: "up"|"down") => void,
  onExit?: (dir: "up"|"down") => void,
};

const infRotAniId = nanoid(6);

const Reason01 = (po: Props) => {
  const {onEnter, onExit} = po;
  const [isActive, setIsActive] = useState(false);

  return  (
    <div
      css={[
        theme.mixin.sectionBaseStyles,
        {
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }
      ]}
    >
      <div className={["narration-container"].join(" ")}>
        <BasicNarration css={{height: 180 * refVh}}
          onEnter={dir => {setIsActive(true); onEnter && onEnter(dir)}}
          onExit={dir => {setIsActive(false); onExit && onExit(dir)}}
        >
          One is that extraordinary monetary and fiscal stimulus over the past year has created one of the broadest and earliest valuation problems of the past 25 years.
        </BasicNarration>
      </div>

      <BasicChartCont
        css={{flexGrow: 1}}
        chartTitle="Figure 2: Proportion of Equity & FICC markets trading rich to long-term valuation metrics is unusually high for a young expansion"
        chartFooter={(
          <p>Source: J.P. Morgan</p>
        )}
      >
        <img src={n1MarkDiskUrl} alt=""
          css={{
            position: "absolute",
            top: "4rem",
            right: "-8rem",

            width: "18rem",
            objectFit: "contain",

            [`@keyframes ${infRotAniId}`]: {
              "0%": {
                opacity: 0.3,
              },
              "100%": {
                opacity: 0.6,
              },
            },
            animation: `${infRotAniId} 3s ease-in-out 1s infinite alternate`,
          }} 
        />
        <Chart css={{height: "60%"}} isActive={isActive} />
      </BasicChartCont>

    </div>
  );
}

export default Reason01;
