/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';
import { nanoid } from 'nanoid';

import theme from "../../../../../../theme";
// eslint-disable-next-line import/no-webpack-loader-syntax
import bareDiskStr from "!!raw-loader!../../assets/bare-disk.svg";

export enum State {
  hidden,
  minimized,
  enlarged,
  dissipated,
};

export type Props = ComponentProps<"div"> & {
  state: State,
  bottomCss: string,
  leftCss: string,
};

const DiskIndicator = (po: Props) => {
  const {state, className, bottomCss, leftCss, ...poRest} = po;
  const minimizedSize = "3rem";
  const infRotAniId = nanoid(6);
  
  return (
    <div
      className={[
        state !== State.hidden ? "show" : null,
        className,
      ].join(" ")}
      css={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        transition: "all 0.2s ease",
        zIndex: -1,

        "&.show": {
          opacity: 1,
          zIndex: 0,
        },
      }}
      {...poRest}
    >
      {/* a container div, such that we can separate */}
      {/* (translation, enlarge) and (rotation) */}
      <div 
        className={[
          state === State.enlarged ? "enlarged" : null,
          state === State.dissipated ? "dissipated" : null,
        ].join(" ")}
        css={{
          position: "absolute",
          bottom: bottomCss,
          left: leftCss,
          width: minimizedSize,
          height: minimizedSize,
          transform: "translate(-50%, +50%)",
          transition: "all 0.5s ease",

          "&.enlarged": {
            bottom: "50%",
            left: "50%",
            width: "112vw",
            height: "112vw",
            maxWidth: theme.media.screen.fhdWidth * 1.12,
            maxHeight: theme.media.screen.fhdWidth * 1.12,
            
            "svg": {
              strokeWidth: "0.05px",
            },
          },

          "&.dissipated": {
            bottom: "50%",
            left: "50%",
            width: "130vw",
            height: "130vw",
            maxWidth: theme.media.screen.fhdWidth * 1.3,
            maxHeight: theme.media.screen.fhdWidth * 1.3,
            opacity: 0,
          },
        }}
      >
        <div
          css={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "contain",

            "svg": {
              width: "100%",
              height: "100%",
            },

            [`@keyframes ${infRotAniId}`]: {
              "0%": {
                transform: "rotate(0)",
              },
              "100%": {
                transform: "rotate(360deg)",
              },
            },
            animation: `${infRotAniId} 780s linear infinite`,
          }}
          dangerouslySetInnerHTML={{__html: bareDiskStr}}
        />
      </div>
    </div>
  );
}

export default DiskIndicator;
