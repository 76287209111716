/** @jsxImportSource @emotion/react */
import { 
  ComponentProps, useState, 
} from 'react';
import * as d3 from "d3";

import theme from "../../../../../../theme";
import BasicNarration from '../../../../components/BasicNarration/BasicNarration';
import BasicChartCont from '../../../../components/BasicChartCont/BasicChartCont';
import Chart from "./Chart";
import fingerClickIconUrl from "./assets/finger-click-icon.svg";

export type Props = ComponentProps<"div"> & {
};

const chartProgTriggers = Array.from({length:7}, (_, idx) => {
  return (50+50*idx) / (50+50*7);
});

const NarratedChart = (po: Props) => {
  const [isActive, setIsActive] = useState(false);

  return  (
    <div
      css={[
        theme.mixin.sectionBaseStyles,
        {
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }
      ]}
    >
      <div className={["narration-container"].join(" ")}>
        <BasicNarration 
          onEnter={dir => dir === "down" ? setIsActive(true) : null}
          onExit={dir => dir === "up" ? setIsActive(false) : null}
        >
          As a stand-alone asset, cryptocurrencies remain several times more volatile than core asset markets, with 3M realized volatility of 90% compared to about 20% on US Equities and Gold.
        </BasicNarration>

        <BasicNarration
          onEnter={dir => dir === "up" ? setIsActive(true) : null}
          onExit={dir => dir === "down" ? setIsActive(false) : null}
        >
          But coupled with extraordinary returns in some years, crypto has often generated a much higher Sharpe ratio on average than core markets like Equities or hedge assets like Commodities in general and Gold specifically.
        </BasicNarration>
      </div>

      <BasicChartCont
        css={{flexGrow: 1}}
        chartTitle="Figure 3: Cryptocurrencies' risk-adjusted returns have usually beaten Gold, except for 2019-20"
        chartFooter={(
          <>
            <div css={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              fontStyle: "italic",
              fontSize: "1.5rem",
            }}>
              <img src={fingerClickIconUrl} alt=""
                css={{
                  width: "1.8em",
                  height: "1.8em",
                  position: "relative",
                  bottom: "0.3em",
                }}
              />
              Click to select or de-select data in the chart
            </div>

            <p>Source: J.P. Morgan</p>
          </>
        )}
      >
        <Chart css={{height: "65%"}} isActive={isActive} />
      </BasicChartCont>

    </div>
  );
}

export default NarratedChart;
