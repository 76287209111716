/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';

import theme, { refVh } from "../../../../theme";
import { ScrollyStep, ScrollyStepProps } from "../../../scrolly";

export type Props = ComponentProps<"div"> & ScrollyStepProps;

const BasicNarration = (po: Props) => {
  const {
    className, children, 
    onRegStep, onEnter, onExit, onProgChg,
    ...poRest
  } = po;

  return (
    <ScrollyStep
      onRegStep={onRegStep}
      onEnter={onEnter} onExit={onExit} onProgChg={onProgChg}
    >
      <div className={["narration", className].join(" ")}
        css={[ theme.mixin.sideNarrationBaseStyles, {
          height: 100 * refVh
        }]}
        {...poRest}
      >
        {children}
      </div>
    </ScrollyStep>
  );
}

export default BasicNarration;
