/** @jsxImportSource @emotion/react */
import React, { 
  ComponentProps, useEffect, useRef, useState
} from 'react';

import { useScrollyCtx } from '../ScrollyBody/ScrollyBody';

export type Props = ComponentProps<"div"> & {
  onRegStep?: (idx: number, el: HTMLElement | null) => void,
  onEnter?: (dir: "up"|"down") => void,
  onExit?: (dir: "up"|"down") => void,
  onProgChg?: (prog: number) => void,
};

const ScrollyStep = (po: Props) => {
  const {
    className, children,
    onRegStep, onEnter, onExit, onProgChg,
    ...poRest
  } = po;
  const {steps, isDebug} = useScrollyCtx();
  const [actiState, setActiState] = useState<"enter" | "exit" | false>(false);
  const [dir, setDir] = useState<"up" | "down">("down");
  const [stepProg, setStepProg] = useState(0);
  const stepIdxRef = useRef(-1);
  const selfElRef = useRef<HTMLDivElement>(null);

  // on mount, 
  // register the step and push the state setter to the list
  useEffect(()=>{
    selfElRef.current && steps.push({
      el: selfElRef.current,

      actiState: false,
      dir: "down",
      prog: 0,
    
      setStepActi: setActiState,
      setStepDir: setDir,
      setStepProg: setStepProg,
    });

    stepIdxRef.current = steps.length - 1;

    onRegStep && onRegStep(stepIdxRef.current, selfElRef.current);
  }, []);

  // trigger callbacks
  //
  // Note:
  // React cannot update a component while rendering a different component
  // thus we put the callbacks in useEffect, which will be executed
  // after rendering the current component
  useEffect(()=>{
    switch (actiState) {
      case "enter":
        onEnter && onEnter(dir);
        break;

      case "exit":
        onExit && onExit(dir);
        break;

      case false:
      default:
        break;
    }
      
  // callback should not be inside the dependencies list
  // because if a lambda function is supplied
  // it will be treated as new value every time
  // an thus trigger this useEffect on every render
  }, [actiState]);
  useEffect(()=>{
    onProgChg && onProgChg(stepProg)
    
    // callback should not be inside the dependencies list
    // because if a lambda function is supplied
    // it will be treated as new value every time
    // an thus trigger this useEffect on every render
  }, [stepProg]);

  return (
    <div ref={selfElRef}
      className={["scrolly-step", className].join(" ")}
      css={{
        position: "relative",
        outline: isDebug ? "1px dotted #ffff0088" : "none",
        padding: "0.02px",  // prevent children margin collapse into parents'
      }}
      {...poRest}
    >
      {children}

      {isDebug &&
        <div
          className={["debug-info-container"].join(" ")}
          css={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
          }}
        >
          <div
            className={["debug-info"].join(" ")}
            css={{
              position: "sticky",
              top: "50%",
              left: 0,
              marginTop: "2rem",  // rough compensate of the -50% y translate
              transform: "translate(-100%, -100%)",
              
              fontSize: "1.2rem",
            }}
          >
            <p>{`actiState? ${actiState}`}</p>
            <p>{`enter/exit dir? ${dir}`}</p>
            <p>{`actiStepProg? ${stepProg}`}</p>
          </div>
        </div>
      }
    </div>
  );
};

export default ScrollyStep;
