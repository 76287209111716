import { css } from "@emotion/react";
import amplitudeLight from "./assets/fonts/Amplitude-Light.otf";
import amplitudeLightItalic from "./assets/fonts/Amplitude-LightItalic.otf";
import amplitudeRegular from "./assets/fonts/Amplitude-Regular.otf";
import amplitudeRegularItalic from "./assets/fonts/Amplitude-RegularItalic.otf";
import amplitudeMedium from "./assets/fonts/Amplitude-Medium.otf";
import amplitudeMediumItalic from "./assets/fonts/Amplitude-MediumItalic.otf";

const globalCss = css([
  {
    "*": {
      margin: 0,
      padding: 0,
      border: "none",
      boxSizing: "border-box",
    },
    
    "html": {
      fontSize: "10px",
      fontWeight: 200,
      overflowX: "hidden",
    },

    "body": {
      fontFamily: [
        "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        "sans-serif",
      ],
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },

    "code": {
      fontFamily: [
        "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New",
        "monospace"
      ],
    },
  },

  // Note: object style with multiple font-face
  //       work when at top level,
  //       but does not work when nested
  //       see example in the bottom
  {"@font-face": {
      fontFamily: "amplitude",
      src: `url(${amplitudeLight}) format("opentype")`,
      fontWeight: 200,
      fontStyle: "normal",
  }},
  {"@font-face": {
      fontFamily: "amplitude",
      src: `url(${amplitudeLightItalic}) format("opentype")`,
      fontWeight: 200,
      fontStyle: "italic",
  }},
  {"@font-face": {
      fontFamily: "amplitude",
      src: `url(${amplitudeRegular}) format("opentype")`,
      fontWeight: 400,
      fontStyle: "normal",
  }},
  {"@font-face": {
      fontFamily: "amplitude",
      src: `url(${amplitudeRegularItalic}) format("opentype")`,
      fontWeight: 400,
      fontStyle: "italic",
  }},
  {"@font-face": {
      fontFamily: "amplitude",
      src: `url(${amplitudeMedium}) format("opentype")`,
      fontWeight: 500,
      fontStyle: "normal",
  }},
  {"@font-face": {
      fontFamily: "amplitude",
      src: `url(${amplitudeMediumItalic}) format("opentype")`,
      fontWeight: 500,
      fontStyle: "italic",
  }},
]);

export default globalCss;

// Note: example of not working font-face setting.
//       object style with multiple font-face 
//       does not work when nested
// "@font-face": [
//   {
//     fontFamily: "amplitude",
//     src: `url(${amplitudeLight}) format("opentype")`,
//     fontWeight: 200,
//     fontStyle: "normal",
//   },
//   {
//       fontFamily: "amplitude",
//       src: `url(${amplitudeLightItalic}) format("opentype")`,
//       fontWeight: 200,
//       fontStyle: "italic",
//   },
//   {
//       fontFamily: "amplitude",
//       src: `url(${amplitudeRegular}) format("opentype")`,
//       fontWeight: 400,
//       fontStyle: "normal",
//   },
//   {
//       fontFamily: "amplitude",
//       src: `url(${amplitudeRegularItalic}) format("opentype")`,
//       fontWeight: 400,
//       fontStyle: "italic",
//   },
//   {
//       fontFamily: "amplitude",
//       src: `url(${amplitudeMedium}) format("opentype")`,
//       fontWeight: 500,
//       fontStyle: "normal",
//   },
//   {
//       fontFamily: "amplitude",
//       src: `url(${amplitudeMediumItalic}) format("opentype")`,
//       fontWeight: 500,
//       fontStyle: "italic",
//   },
// ],
