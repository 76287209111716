import { css } from "@emotion/react";

const theme = {
  color: {
    white: "#FFFFFF",
    paleGrey: "#A0A0A020",
    lightGrey: "#CCCCCCAA",
    cyberBlue: "#0C49A0",
    neonBlue: "#00FFFF",
    cellRed: "#5B234C",
    cellGreen: "#1C8956",
  },
  font: {
    family: {
      main: "amplitude"
    },
    weight: {
      ultraLight: 100,
      light: 200,
      normal: 400,
      medium: 500,
      bold: 700,
      ultraBold: 800,
    }
  },
  media: {
    screen: {
      smMinWidth: 576,
      mdMinWidth: 768,
      lgMinWidth: 992,
      xlMinWidth: 1200,
      xxlMinWidth: 1400,
      fhdWidth: 1920,
      refDesktopWidth: 1400,
      refDesktopHeight: 760,
    },
  },
  mixin: {
    // TODO: remove 'sectionBaseStyles'?
    get sectionBaseStyles() {
      // @ts-ignore
      delete this.sectionBaseStyles;
      // @ts-ignore
      return this.sectionBaseStyles = css({
      });
    },
    get narrationBaseStyles() {
      // @ts-ignore
      delete this.narrationBaseStyles;
      // @ts-ignore
      return this.narrationBaseStyles = css({
        padding: "3rem 3rem",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "2rem",
        
        fontSize: "2.2rem",
        lineHeight: 1.3,
      });
    },
    get sideNarrationBaseStyles() {
      // @ts-ignore
      delete this.sideNarrationBaseStyles;
      // @ts-ignore
      return this.sideNarrationBaseStyles = css([
        theme.mixin.narrationBaseStyles,
        {
          alignItems: "start",
          background: theme.color.paleGrey,
          width: "34rem",
        },
      ]);
    },
  },
};

export default theme;
// export const refVh = theme.media.screen.refDesktopHeight / 100;
export const refVh = window.innerHeight / 100;
