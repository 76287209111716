/** @jsxImportSource @emotion/react */
import { ComponentProps, useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import * as d3 from "d3";
import lottie, { AnimationItem } from "lottie-web";

import theme, { refVh } from "../../../../../../theme";
import BasicNarration from '../../../../components/BasicNarration/BasicNarration';
import BasicChartCont from '../../../../components/BasicChartCont/BasicChartCont';
import n3MarkDiskUrl from "../../assets/n3-mark-disk.svg";
import umbrellaAniJson from "../../assets/umbrella-ani.json";

type LottieAni = {
  instance: AnimationItem,
  duration: number,  // ms
  frames: number,
}

export type Props = ComponentProps<"div"> & {
  onEnter?: (dir: "up"|"down") => void,
  onExit?: (dir: "up"|"down") => void,
};

const infRotAniId = nanoid(6);
const umbrellaAniScale = d3.scaleLinear().domain([0.3, 0.9]).clamp(true);

const Reason03 = (po: Props) => {
  const {onEnter, onExit} = po;
  const [umbrellaAni, setUmbrellaAni] = useState<LottieAni|null>(null);
  const umbrellaAniElRef = useRef<HTMLDivElement>(null);

  const onProgChg = (prog: number) => {
    if (umbrellaAni == null) return;
    umbrellaAni.instance.goToAndStop(umbrellaAniScale(prog) * umbrellaAni.duration);
  }

  useEffect(()=> {
    const umbrellaAniEl = umbrellaAniElRef.current!;
    
    const aniItem = lottie.loadAnimation({
      container: umbrellaAniEl,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: umbrellaAniJson,
    });

    setUmbrellaAni({
      instance: aniItem,
      // x1000 because it return sec but goToAndStop seems to except ms
      duration: aniItem.getDuration(false) * 1000,
      frames: aniItem.getDuration(true),
    })
  }, []);

  return  (
    <div
      css={[
        theme.mixin.sectionBaseStyles,
        {
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }
      ]}
    >
      <div className={["narration-container"].join(" ")}>
        <BasicNarration css={{height: 180 * refVh}}
          onEnter={onEnter}
          onExit={onExit}
          onProgChg={onProgChg}
        >
          Conventional hedges like DM Bonds barely serve as insurance when US 10Y rates are near 1%.
        </BasicNarration>
      </div>

      <BasicChartCont css={{flexGrow: 1}}>
        <div>
          <img src={n3MarkDiskUrl} alt=""
            css={{
              position: "absolute",
              top: "4rem",
              right: "-8rem",

              width: "18rem",
              objectFit: "contain",

              [`@keyframes ${infRotAniId}`]: {
                "0%": {
                  opacity: 0.3,
                },
                "100%": {
                  opacity: 0.6,
                },
              },
              animation: `${infRotAniId} 3s ease-in-out 1s infinite alternate`,
            }} 
          />

          <div ref={umbrellaAniElRef} 
            css={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "40rem",
              transform: "translate(-50%, -50%)",
              opacity: 0.8,
            }}
          />
        </div>
      </BasicChartCont>

    </div>
  );
}

export default Reason03;
