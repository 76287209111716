/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';

import theme, { refVh } from "../../../../../../theme";
import { ScrollyStep } from "../../../../../scrolly";
import n1markDiskUrl from "./assets/n1-mark-disk.svg";

export type Props = ComponentProps<"div"> & {
};

const Splash = (po: Props) => {

  return  (
    <ScrollyStep>
      <div css={{
        height: 180 * refVh,
        padding: `${50*refVh}px 8rem 0 8rem`,
      }}>
        <div
          className={["splash"].join(" ")}
          css={{
            position: "sticky",
            top: "50%",
            transform: "translate(0, -50%)",

            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <div css={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <img src={n1markDiskUrl} alt=""
              css={{
                margin: "0 3.5rem",
                width: "10rem",
                height: "10rem",
              }}
            />

            {/* title and subtitle text block */}
            <div>
              <h2 css={{
                display: "flex",
                alignItems: "center",
                fontSize: "3.5rem",
                fontWeight: theme.font.weight.normal,
                textShadow: `0 0 0.5em ${theme.color.white}`,
              }}>
                Improvement in long-term portfolio efficiency
              </h2>

              <p css={{
                fontSize: "2.2rem",
                color: theme.color.lightGrey,
              }}>
                Do small allocationraise a multi-asset portfolio’s risk adjustedreturns
              </p>
            </div>

          </div>
        </div>
      </div>
    </ScrollyStep>
  );
}

export default Splash;
