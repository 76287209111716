/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';

import theme from "../../../../../../theme";
import BasicNarration from '../../../../components/BasicNarration/BasicNarration';
import BasicChartCont from '../../../../components/BasicChartCont/BasicChartCont';
import upsAndDownsUrl from "./assets/ups-and-downs.svg";

export type Props = ComponentProps<"div"> & {
};

const Insights = (po: Props) => {
  return  (
    <div
      css={[
        theme.mixin.sectionBaseStyles,
        {
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }
      ]}
    >
      <div className={["narration-container"].join(" ")}>
        <BasicNarration>
          <p>Perhaps market dynamics will be different during an equility market correction driven by much higher US inflation and a more durable loss of confidence in the dollar (none of the episodes in table2 were driven primarily by the upside surprises on inflation).</p>
          <p>But until and unless those macro concerns materialize, crypto's ownership structure inclines it to underperform in a macro crisis those very currencies it aspires to replace.</p>
        </BasicNarration>
      </div>

      <BasicChartCont css={{flexGrow: 1}}>
        <div>
          <img src={upsAndDownsUrl} alt=""
            css={{
              display: "block",
              margin: "auto",
              width: "70%",
            }}
          />
        </div>
      </BasicChartCont>

    </div>
  );
}

export default Insights;
