/** @jsxImportSource @emotion/react */
import React, { 
  ComponentProps, useContext, useEffect, useRef,
} from "react";
import * as d3 from "d3";
import scrollama from "scrollama";
// ref: https://www.grzegorowski.com/scrollmagic-setup-for-webpack-commonjs
// import ScrollMagic from "scrollmagic/scrollmagic/uncompressed/ScrollMagic";
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

type StepModelCtrl = {
  el: HTMLElement,

  actiState: "enter" | "exit" | false,
  dir: "up" | "down",
  prog: number,

  setStepActi: (actiState: "enter" | "exit" | false) => void,
  setStepDir: (dir: "up" | "down") => void,
  setStepProg: (prog: number) => void,
}

type ScrollyContext = {
  steps: StepModelCtrl[],
  isDebug: boolean,
};
const ScrollyCtx = React.createContext<ScrollyContext>({
  // the default value here doesn't matter much, 
  // as Provider will take precedance
  steps: [],
  isDebug: false,
});
// useContext wrapped in func because 
// it cannot be called outside of functional components 
export const useScrollyCtx = () => useContext(ScrollyCtx);

export type Props = ComponentProps<"div"> & {
  onScrollyLoad?: (stepModelCtrl: StepModelCtrl[]) => void,
  onScrollyChg?: (stepModelCtrl: StepModelCtrl[]) => void,
  onSecProgChg?: (progList: number[]) => void,
  isDebug?: boolean,
};

const ScrollyBody = (po: Props) => {
  const {
    className, children, isDebug, 
    onScrollyLoad, onScrollyChg, 
    onSecProgChg = () => {},
    ...poRest
  } = po;
  const stepsRef = useRef<StepModelCtrl[]>([]);
  const scrollerRef = useRef<scrollama.ScrollamaInstance>();
  const secElList = useRef<HTMLElement[]>([]);

  useEffect(() => {
    // instantiate the scrollama
    const scroller = scrollerRef.current = scrollama();

    // setup the instance, pass callback functions
    //
    // Notes for callbacks:
    // index start from 1 if css selector is used to select steps
    // and start from 0 if list of HTMLElement is used
    scroller
      .setup({
        step: stepsRef.current.map(d => d.el),
        progress: true,
        // @ts-ignore
        threshold: 8,  // default is 4
        debug: isDebug ? isDebug : false,
      })

    secElList.current = d3.selectAll<HTMLElement, any>(".page.home .section").nodes();

    // setup resize event
    window.addEventListener("resize", scroller.resize);

    // call life-cycle callbacks
    onScrollyLoad && onScrollyLoad(stepsRef.current);

    return () => {
      scroller.destroy();
    }
  }, [isDebug]);

  // call life-cycle callbacks
  useEffect(() => {
    scrollerRef.current
      ?.onStepEnter((args) => {
        const { element, index, direction } = args;
        const step = stepsRef.current.find(d => d.el === element);

        if (step != null) {
          step.actiState = "enter";
          step.setStepActi(step.actiState);
          
          step.dir = direction;
          step.setStepDir(step.dir);

          step.prog = direction === "down" ? 0 : 1;
          step.setStepProg(step.prog);
        }
      })
      .onStepExit((args) => {
        const { element, index, direction } = args;
        const step = stepsRef.current.find(d => d.el === element);

        if (step != null) {
          step.actiState = "exit";
          step.setStepActi(step.actiState);
          
          step.dir = direction;
          step.setStepDir(step.dir);

          step.prog = direction === "down" ? 1 : 0;
          step.setStepProg(step.prog);
        }
      })
      .onStepProgress(args => {
        const { element, index, progress } = args;

        window.requestAnimationFrame(() => {
          const step = stepsRef.current.find(d => d.el === element);

          if (step != null) {
            step.prog = progress;
            step.setStepProg(step.prog);
          }

          onSecProgChg(
            // calculate the prog using vert mid screen as ref mark
            secElList.current.map(el => {
              const secProgRaw = 
                (-el.getBoundingClientRect().y + window.innerHeight/2) 
                / el.getBoundingClientRect().height;
              
              const secProg = Math.min(Math.max(0, secProgRaw), 1);
              return secProg;
            })
          );
        })
      });

  }, [onScrollyLoad, onSecProgChg]);

  return (
    <ScrollyCtx.Provider value={{
      steps: stepsRef.current,
      isDebug: isDebug ? isDebug : false,
    }}>
      <div className={["scrolly-body", className].join(" ")} {...poRest}>
        {children}
      </div>
    </ScrollyCtx.Provider>
  );
};

export default ScrollyBody;
