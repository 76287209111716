/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';

import theme, { refVh } from "../../../../theme";
import { ScrollyStep } from "../../../scrolly";
import splashUrl from "./assets/conclusion-bkg.svg";

export type Props = ComponentProps<"div"> & {
};

const Conclusion = (po: Props) => {
  return  (
    <ScrollyStep>
      <div
        className={["conclusion", "section", po.className].join(" ")}
        css={{
          height: 250 * refVh,
          padding: `${50*refVh}px 8rem 0 8rem`,
          marginBottom: 51 * refVh,
        }}
      >
        <div
          className={["splash"].join(" ")}
          css={{
            position: "sticky",
            top: "50%",
            transform: "translate(0, -50%)",

            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <img src={splashUrl} alt=""
            css={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: 70 * refVh,

              opacity: 0.5,
            }}
          />
          <div css={{
            fontSize: "2.8rem",
            fontWeight: theme.font.weight.normal,
            textAlign: "center",

            maxWidth: "75rem",

            "p": {
              marginTop: "2rem",
            },
          }}>
            <p>
              <span css={{
                fontSize: "2em",
                textShadow: `0 0 0.5em ${theme.color.white}`,
              }}>Our conclusions<br /></span>
              <span>haven't changed much in the three years we have been tracking this diversification issue.</span>
            </p>
            <p>Bitcoin improves long-term portfolio efficiency, but its contribution will probably diminish as its mainstreaming increases its correlation with cyclical assets. And crypto continues to rank as the least reliable hedge during periods of acute market stress.</p>
          </div>
        </div>
      </div>
    </ScrollyStep>
  );
}

export default Conclusion;
