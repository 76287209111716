/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';

import Splash from "./Splash";
import NarratedTable from "./NarratedTable";
import Insights from "./Insights";

export type Props = ComponentProps<"div"> & {
};

const Criteria02 = (po: Props) => {

  return  (
    <div>
      <Splash />
      <NarratedTable />
      <Insights />
    </div>
  );
}

export default Criteria02;
