/** @jsxImportSource @emotion/react */
import { ComponentProps, useState } from 'react';

import Step01 from "./Step01";
import Step02 from "./Step02";

export type Props = ComponentProps<"div"> & {
  onEnter?: (dir: "up"|"down") => void,
  onStep02Enter?: (dir: "up"|"down") => void,
  onExit?: (dir: "up"|"down") => void,
};

const Intro = (po: Props) => {
  const {onEnter, onStep02Enter, onExit} = po;
  const [isStep02Active, setIsStep02Active] = useState(false);

  return  (
    <div>
      <Step01
        onEnter={(dir) => {onEnter && onEnter(dir);}}
        onExit={(dir) => {dir === "up" && onExit && onExit(dir);}}
      />
      <Step02 isActive={isStep02Active}
        onEnter={(dir) => {setIsStep02Active(true); onStep02Enter && onStep02Enter(dir);}}
        onExit={(dir) => {setIsStep02Active(false); dir === "down" && onExit && onExit(dir);}}
      />
    </div>
  );
}

export default Intro;
