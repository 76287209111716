/** @jsxImportSource @emotion/react */

import { Home } from "./pages"

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
