/** @jsxImportSource @emotion/react */
import { ComponentProps } from 'react';

import theme, { refVh } from "../../../../theme";
import { ScrollyStep } from "../../../scrolly";

export type Props = ComponentProps<"div"> & {
  onEnter?: () => void,
  onExit?: () => void,
  onProgChg?: (prog: number) => void,
};

const Intro = (po: Props) => {
  const {onEnter, onExit, onProgChg} = po;

  return  (
    <div 
      className={["intro", "section", po.className].join(" ")}
      css={[
        theme.mixin.sectionBaseStyles,
      ]}
    >
      <ScrollyStep {...{onEnter, onExit, onProgChg}}>
        <div
          className={["splash", po.className].join(" ")}
          css={{
            height: 120 * refVh,
            width: "100%",
          }}
        >
          <div css={{
            position: "sticky",
            top: "50%",
            transform: "translate(0, -50%)",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5rem",
            maxWidth: theme.media.screen.lgMinWidth,
            margin: "auto",

            textAlign: "center",
          }}>
            <h2 css={{
              fontWeight: theme.font.weight.normal,
              lineHeight: 1.2,
            }}>
              <span css={{fontSize: "12rem"}}>Cryptocurrencies</span>
              <span css={{fontSize: "4rem"}}>
                <br />
                in multi-asset portfolio?
              </span>
            </h2>
            <p css={{
              fontSize: "1.8rem",
              maxWidth: theme.media.screen.mdMinWidth,
            }}>
              What cryptocurrencies have and haven't done for multi-asset portfolios: Mainstreaming is reducing diversification benefits and leading to failure during a crisis
            </p>
          </div>
        </div>
      </ScrollyStep>
    </div>
  );
}

export default Intro;
