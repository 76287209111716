/** @jsxImportSource @emotion/react */
import { ComponentProps, useState } from 'react';
import { nanoid } from 'nanoid';

import theme, { refVh } from "../../../../../../theme";
import BasicNarration from '../../../../components/BasicNarration/BasicNarration';
import BasicChartCont from '../../../../components/BasicChartCont/BasicChartCont';
import n2MarkDiskUrl from "../../assets/n2-mark-disk.svg";
import dollarTagsUrl from "../../assets/dollar-tag.svg";

export type Props = ComponentProps<"div"> & {
  onEnter?: (dir: "up"|"down") => void,
  onExit?: (dir: "up"|"down") => void,
};

const infRotAniId = nanoid(6);

const Reason02 = (po: Props) => {
  const {onEnter, onExit} = po;
  const [isDollarTagsSplit, setIsDollarTagsSplit] = useState(false);

  const onProgChg = (prog: number) => {
    if (prog < 0.35) {
      setIsDollarTagsSplit(false);
    }
    else if (prog >= 0.35) {
      setIsDollarTagsSplit(true);
    }
  }

  return  (
    <div
      css={[
        theme.mixin.sectionBaseStyles,
        {
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }
      ]}
    >
      <div className={["narration-container"].join(" ")}>
        <BasicNarration css={{height: 180 * refVh}}
          onEnter={onEnter}
          onExit={onExit}
          onProgChg={onProgChg}
        >
          Equity and Credit valuations look record-rich for a very young business cycle.
        </BasicNarration>
      </div>

      <BasicChartCont css={{flexGrow: 1}}>
        <div>
          <img src={n2MarkDiskUrl} alt=""
            css={{
              position: "absolute",
              top: "4rem",
              right: "-8rem",

              width: "18rem",
              objectFit: "contain",

              [`@keyframes ${infRotAniId}`]: {
                "0%": {
                  opacity: 0.3,
                },
                "100%": {
                  opacity: 0.6,
                },
              },
              animation: `${infRotAniId} 3s ease-in-out 1s infinite alternate`,
            }} 
          />

          <img src={dollarTagsUrl} alt=""
            className={[
              isDollarTagsSplit ? "split" : null,
            ].join(" ")}
            css={{
              width: "20rem",
              transition: "all 1s ease",
              transform: "translate(50%, 0) rotate(-45deg)",

              "&.split": {
                transform: "translate(-5%, 0) rotate(-45deg)",
              },
            }}
          />
          <img src={dollarTagsUrl} alt=""
            className={[
              isDollarTagsSplit ? "split" : null,
            ].join(" ")}
            css={{
              width: "20rem",
              transition: "all 1s ease",
              transform: "translate(-50%, 0) rotate(-45deg)",

              "&.split": {
                transform: "translate(5%, 0) rotate(-45deg)",
              },
            }}
          />
        </div>
      </BasicChartCont>

    </div>
  );
}

export default Reason02;
